import {
  from,
  HttpLink,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

import { useAuthStore } from '../stores/auth';
import { useNetworkStore } from '../stores/network';

let apolloClient = null;

// Export a function so we can defer access to the stores
export const getApolloClient = () => {
  // Only run this once
  if (apolloClient) return apolloClient;

  const authStore = useAuthStore();
  const networkStore = useNetworkStore();

  // Add the impersonating_id header for superusers
  const impersonateMiddleware = new ApolloLink((operation, forward) => {
    if (authStore.impersonatingId) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          impersonating_id: authStore.impersonatingId,
        },
      }));
    }
    return forward(operation);
  });

  const retryLink = new RetryLink({ attempts: { max: 10 } });

  const errorLink = onError(({ networkError }) => {
    if (networkError && networkStore.isOnline) networkStore.isOnline = false;
  });

  const successLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((data) => {
      if (!networkStore.isOnline) networkStore.isOnline = true;
      return data;
    });
  });

  const httpLink = new HttpLink({
    uri: import.meta.env.VITE_APOLLO_URL,
    credentials: 'include',
  });

  apolloClient = new ApolloClient({
    link: from([
      impersonateMiddleware,
      retryLink,
      errorLink,
      successLink,
      httpLink,
    ]),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });

  return apolloClient;
};
