import LogRocket from 'logrocket';

import { addSessionURL } from './sentry';

const isEnabled = import.meta.env.PROD && !!import.meta.env.VITE_LOGROCKET_ID;

export const identify = ({ id, email, lastName, firstName }) => {
  if (!isEnabled) return;
  const name = `${firstName} ${lastName}`;
  LogRocket.identify(id, { name, email });
};

export const initialize = () => {
  if (!isEnabled) return;
  LogRocket.init(import.meta.env.VITE_LOGROCKET_ID, {
    network: {
      requestSanitizer: (request) => {
        // Remove the body and security headers from Amazon requests
        // https://docs.logrocket.com/reference/network
        if (request.url.toLowerCase().indexOf('amazonaws.com') !== -1) {
          request.body = undefined;
          request.headers.authorization = undefined;
          request.headers['x-amz-security-token'] = undefined;
        }
        return request;
      },
    },
  });

  // Connect logrocket with sentry
  LogRocket.getSessionURL((sessionURL) => addSessionURL(sessionURL));
};
