import isEmpty from 'lodash/isEmpty';

import { useAuthStore } from '../stores/auth';
import { useDashboardStore } from '../stores/dashboard';

import { setLicenseCode } from '../lib/license';

const checkSuperuser = (to) => {
  // Only allow superusers to navigate to superuser routes
  if (!/superuser/.test(to.name)) return true;
  return useAuthStore().isSuperuser;
};

const checkLicenseCode = (to) => {
  // Only look for a license code on the code route
  if (to.name !== 'code') return true;

  // Only store the code if one was found
  const { code } = to.params;
  if (!isEmpty(code)) setLicenseCode(code);

  const dashboardStore = useDashboardStore();
  dashboardStore.onboardingOrg = 'fetching';

  // Always attempt to continue to the home route
  // NOTE: checkAuthenticated will take over if not logged in
  return { name: 'home' };
};

const checkAuthenticated = (to) => {
  // Prevent an infinite loop
  if (to.name === 'login') return true;

  // Proceed if already logged in
  const authStore = useAuthStore();
  if (authStore.accountId) return true;

  // Proceed if the login was successful, otherwise redirect to the login page
  return authStore
    .login()
    .then(() => true)
    .catch(() => ({ name: 'login' }));
};

export const addGuards = (router) => {
  // Skip guards when testing
  if (import.meta.env.VITE_TEST) return router;

  router.beforeEach(checkLicenseCode);
  router.beforeEach(checkAuthenticated);
  router.beforeEach(checkSuperuser);
  return router;
};
