import FloatingVue from 'floating-vue';
import { polyfill } from 'seamless-scroll-polyfill';
import { createApp } from 'vue';
import { createHead } from '@unhead/vue';
import { createPinia } from 'pinia';
import {
  DefaultApolloClient,
  provideApolloClient,
} from '@vue/apollo-composable';

import App from './App.vue';
import router from './router';
import * as sentry from './lib/sentry';
import * as logrocket from './lib/logrocket';
import * as background from './lib/background';
import { getApolloClient } from './lib/apollo';

import './assets/app.css';
import 'floating-vue/dist/style.css';

polyfill();

const app = createApp(App);
const head = createHead();

// Initialize tracking (production only)
sentry.initialize({ app, router });
logrocket.initialize();

app.use(router);
app.use(head);
app.use(FloatingVue, { themes: { 'player-tooltip': { $extend: 'tooltip' } } });
app.use(createPinia());
app.provide(DefaultApolloClient, getApolloClient());

provideApolloClient(getApolloClient());

app.mount('#app');
background.start();
