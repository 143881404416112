import gql from 'graphql-tag';

export const AppNavHomeMenuAccountFragment = gql`
  fragment AppNavHomeMenuAccountFragment on Account {
    id
    managedLicenses {
      id
      name
    }
  }
`;
