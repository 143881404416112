import * as heartbeat from './heartbeat';
import * as licenseCode from './license-code';

export const start = () => {
  // Skip background tasks when testing
  if (import.meta.env.VITE_TEST) return;

  heartbeat.start();
  licenseCode.start();
};
