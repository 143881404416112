import gql from 'graphql-tag';

import { AppNavHomeMenuAccountFragment } from './AppNavHomeMenu';
import { AppNavHomeVideoLinksAccountFragment } from './AppNavHomeVideoLinks';
import { AppNavHomeProfileButtonAccountFragment } from './AppNavHomeProfileButton';

export const AppNavHomeAccountFragment = gql`
  fragment AppNavHomeAccountFragment on Account {
    id
    ...AppNavHomeMenuAccountFragment
    ...AppNavHomeVideoLinksAccountFragment
    ...AppNavHomeProfileButtonAccountFragment
  }
  ${AppNavHomeMenuAccountFragment}
  ${AppNavHomeVideoLinksAccountFragment}
  ${AppNavHomeProfileButtonAccountFragment}
`;
