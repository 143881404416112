import { addHours, endOfDay, startOfDay } from 'date-fns';

import { getTimeZoneOffsetInHours } from './time';

const STORAGE_KEY = 'licenseCode';
const WEST_COAST_TIME_ZONE = 'America/Los_Angeles';
const HOURS_BETWEEN_WEST_AND_EAST_COAST = 3;

export const INVALID_CODE_MESSAGE =
  'The code you entered is invalid. Please try again.';

export const getLicenseCode = () => localStorage.getItem(STORAGE_KEY)?.trim();

export const setLicenseCode = (code) => localStorage.setItem(STORAGE_KEY, code);

export const removeLicenseCode = () => localStorage.removeItem(STORAGE_KEY);

// Use this when modifying the start and end dates for a license. When isStart
// is true, dateStringToLicenseDate will convert dateString to a date
// representing midnight on the west coast. When isStart is false, dateString
// will be converted to a date representing midnight on the east coast. We do
// this so anyone in the continental US should see the see the dates as the
// same. Furthermore, it standardizes the times in the database independent of
// the client's current time zone.
// NOTE: This assumes dateString is formatted YYYY-MM-DD
export const dateStringToLicenseDate = (dateString, isStart = false) => {
  // Force date to be a local time
  const date = new Date(`${dateString}T12:00`);
  const offset = getTimeZoneOffsetInHours(date, WEST_COAST_TIME_ZONE);

  if (isStart) {
    // Start at midnight on the west coast
    return addHours(startOfDay(date), offset);
  }

  // End at midnight on the east coast
  return addHours(endOfDay(date), offset - HOURS_BETWEEN_WEST_AND_EAST_COAST);
};
