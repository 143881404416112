import random from 'lodash/random';
import { addMinutes, startOfDay } from 'date-fns';

const MORNING_HOUR_STOP = 7;
const MORNING_HOUR_START = 5;

const splitSeconds = (time) => {
  let seconds = Math.floor(time);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);

  seconds = seconds - hours * 3600 - minutes * 60;
  return [hours, minutes, seconds];
};

const toHMS = (time) => {
  const [hours, m, s] = splitSeconds(time);

  const minutes = m < 10 ? `0${m}` : m;
  const seconds = s < 10 ? `0${s}` : s;

  const result = hours === 0 ? [minutes, seconds] : [hours, minutes, seconds];
  return result.join(':');
};

const formatElapsedSeconds = (seconds) => {
  const parts = [];
  const [h, m, s] = splitSeconds(seconds);

  if (h) parts.push(`${h} hours`);
  if (m) parts.push(`${m} minutes`);
  if (s) parts.push(`${s} seconds`);

  return parts.join(' ') || '0 seconds';
};

const formatSecondsRemaining = (seconds) => {
  const [h, m, s] = splitSeconds(seconds);

  if (h > 1) return `${h}hr`;
  if (h > 0) return `${h}hr ${m}min`;
  if (m > 10) return `${m}min`;
  if (m > 0) return `${m}min ${s}sec`;
  return `${s}sec`;
};

// Returns the number of hours between the current and the specified time zone
// for a given date. For example, if the caller was on the east coast, the value
// of getTimeZoneOffsetInHours(new Date('7/15/22'), 'America/Los_Angeles') would
// be 3. Note that a negative value would be returned if the caller was west of
// the given time zone.
const getTimeZoneOffsetInHours = (date, timeZone) => {
  const offsetDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return (date - offsetDate) / 1000 / 3600;
};

// Returns the given date with it's time shifted to a random point between
// MORNING_HOUR_START and MORNING_HOUR_STOP
const getRandomTimeInTheMorning = (date = new Date()) => {
  const minutes =
    random(MORNING_HOUR_START, MORNING_HOUR_STOP - 1) * 60 + random(60);
  return addMinutes(startOfDay(date), minutes);
};

export {
  toHMS,
  formatElapsedSeconds,
  formatSecondsRemaining,
  getTimeZoneOffsetInHours,
  getRandomTimeInTheMorning,
};
