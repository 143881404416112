import gql from 'graphql-tag';

export const AppNavHomeProfileButtonAccountFragment = gql`
  fragment AppNavHomeProfileButtonAccountFragment on Account {
    id
    lastName
    firstName
    thumbnailUrl
  }
`;
