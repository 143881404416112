import gql from 'graphql-tag';
import isEmpty from 'lodash/isEmpty';
import { useMutation } from '@vue/apollo-composable';

import { useAuthStore } from '../../stores/auth';
import { useDashboardStore } from '../../stores/dashboard';
import { getLicenseCode, removeLicenseCode } from '../license';

import { OnboardingLicensesPageOrganizationFragment } from '../../components/Onboarding/Licenses/OnboardingLicensesPage';

let intervalId = null;
const ONE_SECOND = 1000;

const stop = () => clearInterval(intervalId);

const joinAndRemove = (code) => {
  stop();
  const { mutate } = useMutation(gql`
    mutation ($code: String) {
      joinOrganization(code: $code) {
        id
        ...OnboardingLicensesPageOrganizationFragment
      }
    }
    ${OnboardingLicensesPageOrganizationFragment}
  `);
  const dashboardStore = useDashboardStore();
  mutate({ code })
    .then((result) => {
      const org = result.data.joinOrganization;
      dashboardStore.invalidCode = null;
      dashboardStore.hasJoinedOrg = true;
      dashboardStore.onboardingOrg = org;
      // Was a license code or org code with single license, remove code
      if (org === null) removeLicenseCode();
    })
    .catch((error) => {
      if (error.message === 'Invalid code') {
        dashboardStore.invalidCode = code;
      }
    });
};

const checkLicenseCode = () => {
  const code = getLicenseCode();
  if (isEmpty(code)) return;

  const authStore = useAuthStore();
  if (!authStore.accountId || authStore.isSuperuser) return;

  // Only attempt to join if logged in and a code was found
  joinAndRemove(code);
};

export const start = () => {
  // Repeatedly check for a license code and attempt to join a license
  intervalId = setInterval(() => {
    if (!document.hidden) checkLicenseCode();
  }, ONE_SECOND);
};
