import * as Sentry from '@sentry/vue';

const isEnabled = import.meta.env.PROD && !!import.meta.env.VITE_SENTRY_DSN;

export const initialize = ({ app, router }) => {
  if (!isEnabled || !app || !router) return;

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracesSampleRate: 0.25,
  });
};

export const identify = ({ id, email }) => {
  if (!isEnabled || !id || !email) return;
  Sentry.setUser({ id, email });
};

export const addSessionURL = (sessionURL) => {
  const scope = Sentry.getCurrentScope();
  scope.setExtra('sessionURL', sessionURL);
};
