import gql from 'graphql-tag';
import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useMutation, useApolloClient } from '@vue/apollo-composable';

import * as sentry from '../lib/sentry';
import * as logrocket from '../lib/logrocket';

export const useAuthStore = defineStore('auth', () => {
  const accountId = ref(null);
  const isSuperuser = ref(false);
  const impersonatingId = ref(null);

  const login = () =>
    new Promise((resolve, reject) => {
      const { client } = useApolloClient();

      client
        .query({
          query: gql`
            {
              account {
                id
                email
                lastName
                firstName
                isSuperuser
              }
            }
          `,
        })
        .then(({ data }) => {
          const { id, email, lastName, firstName } = data.account;

          accountId.value = id;
          isSuperuser.value = data.account.isSuperuser;
          impersonatingId.value = null;

          sentry.identify({ id, email });
          logrocket.identify({ id, email, lastName, firstName });
          resolve(id);
        })
        .catch(reject);
    });

  const logout = ({ router, apolloClient }) => {
    const { mutate, onDone } = useMutation(gql`
      mutation {
        logout
      }
    `);

    onDone(() => {
      accountId.value = null;
      isSuperuser.value = false;
      impersonatingId.value = null;

      apolloClient.clearStore();
      router.push({ name: 'login' });
    });

    mutate();
  };

  const impersonate = ({ id, router, apolloClient }) => {
    if (!isSuperuser.value) return;

    isSuperuser.value = false;
    impersonatingId.value = id;

    apolloClient.resetStore();
    router.push({ name: 'home' });
  };

  return {
    accountId,
    isSuperuser,
    impersonatingId,
    login,
    logout,
    impersonate,
  };
});
