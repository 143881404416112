import gql from 'graphql-tag';

import { AppNavHomeAccountFragment } from '../../App/Nav/Home/AppNavHome';

export const OnboardingLicensesPageAccountFragment = gql`
  fragment OnboardingLicensesPageAccountFragment on Account {
    id
    ...AppNavHomeAccountFragment
  }
  ${AppNavHomeAccountFragment}
`;

export const OnboardingLicensesPageOrganizationFragment = gql`
  fragment OnboardingLicensesPageOrganizationFragment on Organization {
    id
    name
    activeLicenses {
      id
      code
      name
    }
  }
`;
