import { createRouter, createWebHistory } from 'vue-router';

import { addGuards } from './guards';

import EmptyView from '../views/EmptyView.vue';

let routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/c/:code?',
    name: 'code',
    component: EmptyView,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
  },
  {
    path: '/admin/:id/',
    name: 'admin',
    component: () => import('../views/Admin/AdminView.vue'),
    children: [
      {
        path: 'teachers/:teacherId?',
        name: 'admin-teachers',
        component: () => import('../views/Admin/AdminTeachersView.vue'),
      },
      {
        path: 'administrators',
        name: 'admin-administrators',
        component: () => import('../views/Admin/AdminAdministratorsView.vue'),
      },
      {
        path: 'invite',
        name: 'admin-invite',
        component: () => import('../views/Admin/AdminInviteView.vue'),
      },
      {
        path: 'account',
        name: 'admin-account',
        component: () => import('../views/Admin/AdminAccountView.vue'),
      },
    ],
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/ProfileView.vue'),
  },
  {
    path: '/record',
    name: 'record',
    component: () => import('../views/RecordView.vue'),
  },
  {
    path: '/upload',
    name: 'upload',
    component: () => import('../views/UploadView.vue'),
  },
  {
    path: '/video/:id',
    name: 'video',
    component: () => import('../views/VideoView.vue'),
  },
  {
    path: '/cycle/:id',
    name: 'cycle',
    component: () => import('../views/Cycle/CycleView.vue'),
  },
  {
    path: '/cycle/create',
    name: 'cycle-create',
    component: () => import('../views/Cycle/CycleCreateView.vue'),
  },
  {
    path: '/superuser',
    name: 'superuser',
    component: () => import('../views/Superuser/SuperuserView.vue'),
    children: [
      {
        path: 'accounts',
        name: 'superuser-accounts',
        component: () => import('../views/Superuser/SuperuserAccountsView.vue'),
      },
      {
        path: 'domains',
        name: 'superuser-domains',
        component: () => import('../views/Superuser/SuperuserDomainsView.vue'),
      },
      {
        path: 'organizations',
        name: 'superuser-organizations',
        component: () =>
          import('../views/Superuser/SuperuserOrganizationsView.vue'),
      },
      {
        path: 'organization/:id',
        name: 'superuser-organization',
        props: true,
        component: () =>
          import('../views/Superuser/SuperuserOrganizationView.vue'),
      },
    ],
  },
];

if (import.meta.env.VITE_TEST) {
  routes = routes.map((route) => {
    return {
      ...route,
      component: () => import('../views/TestView.vue'),
    };
  });
  routes.push({
    path: '/',
    name: 'test',
    component: () => import('../views/TestView.vue'),
  });
}

const router = createRouter({
  routes,
  history: createWebHistory(import.meta.env.BASE_URL),
});

// Error handling for failed chunk loading after new deployment
// https://stackoverflow.com/questions/69300341/
router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location.href = to.fullPath;
    }
  }
});

export default addGuards(router);
