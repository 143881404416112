import gql from 'graphql-tag';
import noop from 'lodash/noop';
import { useMutation } from '@vue/apollo-composable';

import { snooze } from '../wait';
import { useAuthStore } from '../../stores/auth';

const TEN_SECONDS = 10 * 1000;
const SIXTY_SECONDS = 60 * 1000;

const sendHeartbeat = () => {
  const authStore = useAuthStore();

  // Prevent heartbeat when not signed in
  if (!authStore.accountId) return;
  // Prevent heartbeat when impersonating
  if (authStore.impersonatingId) return;

  const { mutate, onError } = useMutation(gql`
    mutation {
      heartbeat
    }
  `);

  onError(noop);
  mutate();
};

export const start = () => {
  // Send an initial heartbeat shortly after the app starts
  snooze(TEN_SECONDS).then(sendHeartbeat);

  // Send regular heartbeats while the app remains open and active
  setInterval(() => {
    if (!document.hidden) sendHeartbeat();
  }, SIXTY_SECONDS);
};
