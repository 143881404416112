import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useDashboardStore = defineStore('dashboard', () => {
  const invalidCode = ref(null);
  const hasJoinedOrg = ref(false);
  const isNewSession = ref(true);
  const onboardingOrg = ref(null);
  const isOnboardingPath = ref(false);

  return {
    invalidCode,
    hasJoinedOrg,
    isNewSession,
    onboardingOrg,
    isOnboardingPath,
  };
});
